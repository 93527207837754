
import { defineComponent } from "vue";
import store from "@/store/index";
import AuthGuard from "./AuthGuard.vue";

export default defineComponent({
  name: "New",
  components: {
    AuthGuard,
  },
  methods: {
    handleValid: function () {
      store.dispatch("workspace/create").then((workspace: string) => {
        this.$router.push(`/-/${workspace}`);
      });
    },
  },
});
